import React from "react"
import { Container } from "react-bootstrap"
import { parseHTML } from "../../common/utils/utils"
import "./BannerHome.scss"
import { motion } from "framer-motion"
import { contentVariant } from "../../common/animations"

const BannerHome = ({ title, description }) => {
  return (
    <div className="banner-home-wrap">
      <Container className="banner-home-section">
        <motion.h1
          className="banner-title"
          variants={contentVariant}
          initial="initial"
          animate="animate"
        >
          {title}
        </motion.h1>
        <motion.div
          className="banner-description"
          variants={contentVariant}
          initial="initial"
          animate={{
            ...contentVariant.animate,
            transition: {
              ...contentVariant.animate.transition,
              delay: 0.25,
            },
          }}
        >
          {parseHTML(description)}
        </motion.div>
      </Container>
    </div>
  )
}

export default BannerHome
