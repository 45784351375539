import React from "react"
import "../styles/main.scss"
import { graphql } from "gatsby"
import clsx from "clsx"
import BannerHome from "../components/BannerHome/BannerHome"
import SEO from "../components/SEO/seo"
import loadable from "@loadable/component"
import VenueHighlight from "../components/VenueHighlight/VenueHighlight"
import { motion } from "framer-motion"
import { layoutVariant } from "../common/animations"
import HomeStructuredData from "../components/HomeStructuredData"
import useHasMouseMoved from "../hooks/useHasMouseMoved"
const VenueSlider = loadable(() =>
  import("../components/VenueSlider/VenueSlider")
)
const VideoModule = loadable(() =>
  import("../components/VideoModule/VideoModule")
)
const AboutClientsModule = loadable(() =>
  import("../components/AboutClientsModule/AboutClientsModule")
)
const ImageLinksModule = loadable(() =>
  import("../components/ImageLinksModule/ImageLinksModule")
)

const HomeTemplate = ({ data, ...props }) => {
  const mouseMoved = useHasMouseMoved()

  const pageData = data?.strapiPage
  if (!pageData) return null

  const modules = pageData.modules
  const { title, description } = pageData.banner_section
  const meta_title = pageData.seo?.meta_title
  const meta_description = pageData.seo?.meta_description
  const imagetransforms = pageData.imagetransforms
  const strapi_id = pageData.strapi_id

  return (
    <motion.div
      className={clsx("home-template", pageData.page_class)}
      variants={layoutVariant}
      initial="initial"
      animate="animate"
      exit="exit"
      key={pageData.strapi_id}
    >
      <SEO title={meta_title} description={meta_description} />
      <HomeStructuredData />
      {/* <Layout> */}
      <BannerHome title={title} description={description?.data?.description} />
      {mouseMoved &&
        modules?.map(module => (
          <>
            {module.strapi_component === "modules.venue-highlight" && (
              <VenueHighlight
                module={module}
                imagetransforms={imagetransforms}
                strapi_id={strapi_id}
              />
            )}
            {module.strapi_component === "modules.venues-slider" && (
              <VenueSlider module={module} />
            )}
            {module.strapi_component === "modules.video-module" && (
              <VideoModule
                module={module}
                imagetransforms={imagetransforms}
                strapi_id={strapi_id}
              />
            )}
            {module.strapi_component === "modules.about-and-clients" && (
              <AboutClientsModule module={module} />
            )}
            {module.strapi_component === "modules.image-links-module" && (
              <ImageLinksModule
                module={module}
                imagetransforms={imagetransforms}
                strapi_id={strapi_id}
              />
            )}
          </>
        ))}
      {/* </Layout> */}
    </motion.div>
  )
}

export default HomeTemplate

export const query = graphql`
  query ($page_id: Int) {
    strapiPage(strapi_id: { eq: $page_id }) {
      ...pageFragment
      modules {
        ... on STRAPI__COMPONENT_MODULES_VENUE_HIGHLIGHT {
          ...venueHightlightFragment
        }
        ... on STRAPI__COMPONENT_MODULES_VENUES_SLIDER {
          ...venuesSliderFragment
        }
        ... on STRAPI__COMPONENT_MODULES_VIDEO_MODULE {
          ...videoModuleFragment
        }
        ... on STRAPI__COMPONENT_MODULES_ABOUT_AND_CLIENTS {
          ...aboutClientsFragment
        }
        ... on STRAPI__COMPONENT_MODULES_IMAGE_LINKS_MODULE {
          ...imagesLinksFragment
        }
      }
    }
  }
`
