import { Link } from "gatsby"
import React from "react"
import { Container } from "react-bootstrap"
import "./VenueHighlight.scss"
import { venuesURL } from "../../common/utils/urls"
import ImageModule from "../../modules/ImageModule"
import { motion } from "framer-motion"
import { contentVariant } from "../../common/animations"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const VenueHighlight = ({ module, strapi_id, imagetransforms }) => {
  if (!module?.venues) return null

  const { venues } = module

  return (
    <motion.div
      className="venue-highlight-wrap"
      variants={contentVariant}
      initial="initial"
      animate={{
        ...contentVariant.animate,
        transition: {
          ...contentVariant.animate.transition,
          delay: 0.5,
        },
      }}
    >
      <Slider autoplay autoplaySpeed={5000} infinite speed={1000}>
        {venues.map((venue, index) => {
          const { text, image } = venue

          let processedImages = imagetransforms?.image_Transforms
          processedImages ||= JSON.stringify({})

          return (
            <div className="venue-highlight">
              {/* <img
                src={image.url}
                alt={text}
                className="venue-highlight__image"
              /> */}
              <ImageModule
                ImageSrc={image}
                title={text}
                altText={text}
                imagetransforms={processedImages}
                renderer="srcSet"
                imagename={"page.image.highlight_image"}
                strapi_id={strapi_id}
                classNames="venue-highlight__image"
                lazyLoading={false}
              />
              <div className="venue-highlight__overlay"></div>
              <Container className="venue-highlight__name">
                <h3>{text}</h3>
              </Container>
            </div>
          )
        })}
      </Slider>
    </motion.div>
  )
}

export default VenueHighlight
