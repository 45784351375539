import * as React from "react"
import { Helmet } from "react-helmet"

function HomeStructuredData() {
  const site_url = process.env.GATSBY_SITE_URL

  const ldJson = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Blank Canvas",
    url: site_url,
    logo: "https://ggfx-blankcanvas.s3.eu-west-2.amazonaws.com/i.prod/bc_logo_edbe332619.png",
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "+44 (0)20 3757 8403",
      Email: "Info@blankcanvas.co.uk",
      areaServed: "2ND FLOOR, 56 HOXTON SQUARE, LONDON, N1 6PB",
    },
    sameAs: ["https://www.instagram.com/blankcanvas_venues/"],
  }
  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(ldJson, null, 2)}
      </script>
    </Helmet>
  )
}
export default HomeStructuredData
